import Head from 'next/head'

import { Layout } from '../common/types'
import { withLayout } from '../layouts'
import { Header } from '../modules/LandingPage/Header'
import { Join } from '../modules/LandingPage/Join'
import { Manage } from '../modules/LandingPage/Manage'
import { Operations } from '../modules/LandingPage/Operations'
import { Pools } from '../modules/LandingPage/Pools'

const Home = () => (
  <>
    <Head>
      <title>StakeNow.fi — Your Stake, rewarded.</title>
      <meta
        name="description"
        content="StakeNow.fi gives you a 360º view of your investments and lets you manage your Tezos assets in one place."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://stakenow.fi/" />
      <meta property="og:site_name" content="StakeNow.fi" />
      <meta property="og:title" content="StakeNow.fi — Your Stake, rewarded." />
      <meta
        property="og:description"
        content="StakeNow.fi gives you a 360º view of your investments and lets you manage your Tezos assets in one place."
      />
      <meta property="og:image" content="https://stakenow.fi/stakenow-meta.jpg" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://stakenow.fi/" />
      <meta property="twitter:title" content="StakeNow.fi — Your Stake, rewarded." />
      <meta name="twitter:site" content="@StakeNow" />
      <meta name="twitter:creator" content="@StakeNow" />
      <meta
        property="twitter:description"
        content="StakeNow.fi gives you a 360º view of your investments and lets you manage your Tezos assets in one place."
      />
      <meta property="twitter:image" content="https://stakenow.fi/stakenow-meta.jpg" />
      <link rel="icon" href="/favicon.svg" />
    </Head>
    <main className="[--scroll-mt:9.875rem] lg:[--scroll-mt:6.3125rem] overflow-x-hidden js-focus-visible">
      <Header />
      <Operations />
      <Pools />
      <Manage />
      <Join />
    </main>
  </>
)

export default withLayout(Layout.notConnected)(Home)
